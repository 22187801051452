import React from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useShopStore } from '~/domains';
import themeColor from '~/styles/theme/color';

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    fontSize: '1.5rem',
    flexDirection: 'column',
  },
  wrap: {
    textAlign: 'center',
    whiteSpace: 'pre',
  },
  icon: {
    maxWidth: '21.6rem',
  },
  title: {
    marginTop: `${theme.spacing(2)}rem`,
    fontSize: '1.6rem',
    lineHeight: 1.5,
    fontWeight: 500,
    color: themeColor.grey[900],
  },
  sub: {
    marginTop: `${theme.spacing()}rem`,
    fontSize: '1.4rem',
    lineHeight: 1.5,
    color: themeColor.grey[500],
  },
  button: {
    width: '10.4rem',
    height: '4.4rem',
    marginTop: `${theme.spacing(3)}rem`,
  },
}));

type ErrorPropType = {
  iconUrl: string;
  title: string;
  sub: string;
  trackPrevEvent?: () => void;
};

const ErrorLayout: React.FC<ErrorPropType> = ({
  iconUrl,
  title,
  sub,
  trackPrevEvent,
}) => {
  const classes = useStyle();
  const { view } = useShopStore();
  const handleClickPrev = () => {
    if(trackPrevEvent) trackPrevEvent();
    view.goBack();
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.wrap}>
        <img src={iconUrl} alt='404 error' className={classes.icon} />
        <Box className={classes.title}>{title}</Box>
        <Box className={classes.sub}>{sub}</Box>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleClickPrev}
        >
          이전
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorLayout;
